import Mock from "../mock";

const database = {
  information: {
    name: 'Gustave Habimana',
    jobInterest: 'IAM Administrator',
    aboutContent: "Welcome to my internet niche. Feel free to browse on some of my work that I’ve worked on. I have had the privilege of working on diverse projects, ranging from small-scale application development to large-scale cloud transformations and solutions. I’d also love to grab coffee and chat on some collaborations so feel to reach out. ",
    hobbies: "Soccer and Netflix",
    nationality: 'American',
    language: 'English, Kinyarwanda',
    email: '',
    address: 'Atlanta, GA',
    freelanceStatus: 'IAM Administrator',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/gustave-habimana/',
      github: 'https://github.com/gustaveH'
    },
    brandImage: '/images/profile.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/resume.pdf'
  },
  services: [
    {
      title: "Cloud Computing",
      icon: 'aws',
      details: "Data storage and secure access is important anywhere, anytime. Using AWS endless services, I can optimize apps while making them scalable to any work."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "I design, develop, and deploy responsive web apps while making sure they meet user needs. I also maintain existing applications to fit user needs."
    },
    {
      title: "Each one Teach One",
      icon: 'book',
      details: "Learning new technology is hard. I make an effort to help those that are trying to get into technology by providing them tools based on their goals."
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Uza Wa Oli",
      // subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/uzaWaOli2.jpg",
      // largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://www.uzawaoli.com'
    },
    {
      id: 2,
      title: "Le Mageza",
      // subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/lesmageza.jpg",
      // largeImageUrl: [
      //   "/images/portfolio-image-4-lg.jpg",
      //   "/images/portfolio-image-4-lg2.jpg"
      // ],
      url: 'https://www.lesmageza.com'
    },
    {
      id: 3,
      title: "Denwize LLC",
      // subtitle: "Beautiful mug with logo.",// 
      imageUrl: "/images/denwize.jpg",
      url: 'https://www.denwize.com'
    },
  ],
  contactInfo: {
    phoneNumbers: ['+012-3456-7891', '+012-3456-7892'],
    emailAddress: ['admin.sitename@example.com', 'info.sitename@example.com'],
    address: "121 King Street, Melbourne, Victoria 3000, Australia"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});